// import AuthWrapper from 'components/wrappers/auth-wrapper';
// import { useForm } from "react-hook-form";
// import Input from 'components/form-control/input';
import React, { useEffect, useState } from "react";
import { LOGO } from "assets";
import { BALOSH } from "assets";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import Input from "components/form-control/input";
import { getAdminToken, getUser } from "common";
import axios from "axios";
import API_URL from "config";
import LoadingSpinner from "layouts/loadingSpinner";
import moment from "moment";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
interface mainLayoutTypes {
  children: JSX.Element;
}

function ManagerModal(props: any) {
  const user = useSelector(getUser);
  // const { loading, setLoading } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const token = getAdminToken();
  const navigate = useNavigate();
  const [validity, setValidity] = useState("");
  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [phonevalue, setPhoneValue] = useState<any>("");
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });
  useEffect(() => {
    setLoading(true);
    axiosCreate
      .get(`${API_URL}/company`)
      .then(({ data }) => {
        setLoading(false);
        setCompanies(data?.payload[0]);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("an error occured");
        setErr(error?.response?.data?.message);
      });
  }, []);

  const { showmodal, setShowModal } = props;
  const { register, handleSubmit } = useForm();
  const formData = [
    {
      label: "Name",
      placeholder: "enter name",
      type: "text",
      register: { ...register("firstname") },
    },
    {
      label: "Phone number",
      placeholder: "enter phone",
      type: "number",
      register: { ...register("phone") },
      number: true,
    },
    {
      label: "Email address",
      placeholder: "enter email",
      type: "email",
      register: { ...register("email") },
    },
    {
      label: "Designation",
      placeholder: "enter designation",
      type: "text",
      register: { ...register("designation") },
    },
    // {
    //   label: "Address",
    //   placeholder: "enter company address",
    //   type: "text",
    //   register: { ...register("address") },
    // },
    {
      label: "Upload NIN/CERPAC {PDF Format Required}",
      //   placeholder: "enter company description",
      type: "file",
      register: { ...register("nin") },
    },
  ];

  const Select = React.forwardRef(
    ({ onChange, onBlur, name, label }: any, ref: any) => (
      <>
        <label className="font-semibold pb-4 text-gray-300 text-sm">
          {label}
        </label>
        <select
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select enterprise</option>
          {companies?.map((dats: any) => (
            <option value={dats._id}>{dats.name}</option>
          ))}
        </select>
      </>
    )
  );
  const Select2 = React.forwardRef(
    ({ onChange, onBlur, name, label }: any, ref: any) => (
      <>
        <label className="font-semibold pb-2 text-gray-700">{label}</label>
        <select
          value={validity}
          onChange={(e) => setValidity(e.target.value)}
          className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="">Select validity period</option>
          <option value="3 months">3 month</option>
          <option value="6 months">6 month</option>
          <option value="1 year">1 year</option>
        </select>
      </>
    )
  );

  const month3Calculation = () => {
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 3,
      Number(splittingdate[1])
    );

    return monthexpireddate;
  };
  const month6Calculation = () => {
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 6,
      Number(splittingdate[1])
    );

    return monthexpireddate;
  };
  const yearCalculation = () => {
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 12,
      Number(splittingdate[1])
    );

    return monthexpireddate;
  };

  const expiredThree = month3Calculation();
  const expiredSix = month6Calculation();
  const expiredYear = yearCalculation();

  const handleManager = (data: any) => {
    setErr("");
    setMessage("");
    setLoading(true);
    const file = data.nin[0];
    console.log(file, "jere is file");
    window.location.href = "#";
    if (!file) {
      setLoading(false);
      setErr("NIN/CEPAC is required in PDF's format");
    }
    if (file.type !== "application/pdf") {
      setErr("File type must be PDF");
      return;
    }

    if (!isValid) {
      setLoading(false);
      setErr("Input a valid phone number");
      return;
    }
    const handleValidity = () => {
      if (validity === "3 months") {
        return expiredThree.toString();
      } else if (validity === "6 months") {
        return expiredSix.toString();
      } else if (validity === "12 months") {
        return expiredYear.toString();
      } else {
        return "";
      }
    };
    const form = new FormData();
    form.append("image", file);
    form.append("name", data?.firstname);
    form.append("email", data?.email);
    form.append("phone", phonevalue);
    form.append("company", data?.company);
    form.append("designation", data?.designation);
    form.append("expiresAt", handleValidity());
    form.append("validityperiod", validity);

    // form.append("address", data?.address);
    // const info = {
    //   name: data?.firstname,
    //   email: data?.email,
    //   phone: data?.phone,
    //   company: data?.company,
    //   designation: data?.designation,
    //   validityperiod: validity,
    //   expiresAt: handleValidity(),
    // };
    axiosCreate
      .post(`${API_URL}/admin/manager/register`, form)
      .then(({ data }) => {
        axiosCreate
          .put(`${API_URL}/staff/update/${data?.payload[0]?.manager}`, {
            token: data?.payload[0]?.id,
          })
          .then(({ data }) => {
            setMessage("Manager has been registered successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        setErr(error?.response?.data?.message);
      });
  };

  // console.log(user?._id, "userid ooooo")

  const handlePhoneNumberChange = (value: any) => {
    if (value === undefined) {
      setIsValid(false);
    } else {
      setIsValid(isValidPhoneNumber(value));
      setPhoneValue(value);
    }
  };
  return (
    <>
      {showmodal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none rounded-lg">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <form
                className="w-[100%] md:w-[70%] lg:w-[100%]"
                onSubmit={handleSubmit(handleManager)}
              >
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div
                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    style={{
                      background: `linear-gradient(90deg, rgba(12, 57, 174, 0.588542) 46.76%, #AE0C0C 59.32%, rgba(248, 2, 46, 0.0403111) 122.42%, rgba(12, 67, 174, 0) 122.43%)`,
                    }}
                  >
                    <h3 className="text-3xl font-semibold text-white">
                      Manager registration
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative pt-5 lg:w-[600px] lg:px-36 px-4 h-[450px] overflow-y-auto flex-auto">
                    {loading && (
                      <LoadingSpinner
                        loading={loading}
                        setLoading={setLoading}
                      />
                    )}

                    {message && (
                      <div className="p-4 mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                        <span className="text-white font-semibold ">
                          {message}
                        </span>
                        <div onClick={() => setMessage("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    {err && (
                      <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
                        <span className="text-white font-semibold ">{err}</span>
                        <div onClick={() => setErr("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}

                    <Select label="Enterprise" {...register("company")} />
                    {formData.map((item, i) => (
                      <>
                        {item?.number ? (
                          <PhoneInput
                            name="phoneInput"
                            onChange={handlePhoneNumberChange}
                            defaultCountry={"NG"}
                            className={
                              "input-phone-number border border-gray-400 p-3 rounded-lg bg-gray-100 "
                            }
                            // control={control}
                            // rules={{ required: true }}
                            placeholder="your phone"
                          />
                        ) : (
                          <div className="py-2">
                            <Input
                              register={item.register}
                              type={item.type}
                              label={item.label}
                            />
                          </div>
                        )}
                      </>
                    ))}
                    <Select2 label="Validity" {...register("validity")} />

                    <br />
                  </div>
                  {/*footer*/}
                  <div className="flex mb-4 items-center justify-center">
                    <button
                      className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default ManagerModal;
