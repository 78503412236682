import Login from 'features/auth/login';
import DashboardRoutes from 'features/dashboard';
import AuthRoutes from 'features/auth';
import MainLayout from 'layouts';
import Welcome from 'layouts/welcome';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoginRoute from './routes/AdminRoute';
import { useEffect } from 'react';
function App() {
	const navigation = useNavigate();
	useEffect(() => {
		if (window.location.pathname === '/') {
			window.location.href = '/auth/welcome';
		}
	}, [navigation]);
	return (
		<>
			<Routes>
				{/* <Route path="/" element={<LoginRoute />}>
          <Route path="/" element={<Welcome />} />
        </Route> */}
			</Routes>
			<Routes>
				<Route path='/auth/*' element={<AuthRoutes />} />
			</Routes>
			<Routes>
				<Route path='/dashboard/*' element={<DashboardRoutes />} />
			</Routes>
		</>
	);
}

export default App;
