import { Avater } from "assets";
import { getAdminToken } from "common";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "config";
import { format } from "timeago.js";
function FeedbackMessaging() {
  const token = getAdminToken();
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState<any>([]);
  const [message, setMessage] = useState("");
  const { feedback, sender, id } = location?.state;
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });
  const handleSend = () => {
    setMessage("");
    if (message === "") {
      return;
    }
    setMessages([
      ...messages,
      {
        message: message,
      },
    ]);
    // console.log()
    axiosCreate
      .post(`${API_URL}/admin/feedback/reply`, {
        message: message,
        feedbackid: id,
      })
      .then(({ data }) => {
        // console.log("Sent message");
        // window.location.reload();
        // console.log(data?.payload[0])
        // setMessages([...messages, data?.payload[0]]);
      })
      .catch(() => {});
  };

  useEffect(() => {
    axiosCreate
      .get(`${API_URL}/staff/feedback/reply/${id}`)
      .then(({ data }) => {
        setMessages(data?.payload[0]);

        // setMessages(messages)
        // console.log("Sent message");
        // window.location.reload();
        // console.log(data?.payload[0])
        // setMessages([...messages, data?.payload[0]]);
      })
      .catch(() => {});
  }, []);
  //   console.log(location.state);

  //   console.log(messages);
  return (
    <div className="bg-white w-full h-full px-4 lg:px-10 py-8">
      <button
        className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        onClick={() => navigate(-1)}
      >
        Go back
      </button>
      <div className="lg:w-[25%] flex justify-start items-center">
        <img src={Avater} alt="" className="rounded-full w-12 h-12" />
        <div className="ml-2">
          <p className="font-bold text-sm">{sender?.firstname}</p>
        </div>
      </div>
      <p className="text-gray-300">{format(location?.state?.createdAt)}</p>

      <h2 className="font-bold text-3xl pt-6">{location?.state?.subject}</h2>

      <p className="text-sm my-4">Hello Sir/Ma, </p>

      <p className="text-sm">{location?.state?.message}</p>
      {messages?.map((dats: any, index: number) => (
        <div className={`chat-message flex justify-end my-5`} key={index}>
          <div className="flex items-end">
            <div className="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
              <div>
                <span
                  className={`bg-blue-500 text-white  px-4 py-2 rounded-lg inline-block rounded-bl-none`}
                >
                  {dats?.message}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="pt-4">
        <textarea
          className="border border-gray-300 p-4 w-full h-56 outline-none resize-none"
          placeholder="Message"
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
        />
      </div>
      <div className="flex flex-wrap justify-between pt-4">
        <div className="">
          <p className="flex justify-start items-center text-gray-400 text-xs">
            <i className="rounded-full border border-gray-500 w-4 h-4 text-center p-2 text-xs flex justify-center items-center fa-solid fa-exclamation mr-1"></i>
            {sender?.firstname} will be notified
          </p>
        </div>

        <div className="mt-4 lg:mt-0">
          {/* <input id="attachment" hidden type={"file"} />
          <label
            htmlFor="attachment"
            className="transition ease-in-out  delay-150 py-3 px-4 rounded-md text-[#909090] bg-[#C8C8C8] hover:-translate-y-1 hover:scale-110 hover:bg-black hover:text-white duration-300 "
          >
            <i className="fa-solid fa-paperclip"></i> Attachment
          </label> */}
          <button
            onClick={() => handleSend()}
            className="ml-4 transition ease-in-out delay-150 py-2 px-4 rounded-md text-white bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 "
          >
            <i className="fa-solid fa-paper-plane text-white"></i> Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedbackMessaging;
