import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getManagerToken, removeManagerSession } from "common";
import { useJwt } from "react-jwt";
const ManagerRoute = () => {
  const auth = getManagerToken(); // determine if authorized, from context or however you're doing it
  const { decodedToken, isExpired } = useJwt(auth as any);
  // const token = window.localStorage.getItem("token");

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (auth) {
    if (isExpired === true) {
      // AB you can also refresh the token to avoid this stress
      removeManagerSession();
      return <Navigate to="/login" />;
    }
    // console.log(isExpired)
  }

  return auth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/auth/login" />
  );
};

export default ManagerRoute;
