// import AuthWrapper from 'components/wrappers/auth-wrapper';
import { useForm } from 'react-hook-form';
// import Input from 'components/form-control/input';
import { LOGO } from 'assets';
import { BALOSH } from 'assets';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useState } from 'react';
import { TableComponent } from 'components/table';

interface mainLayoutTypes {
	children: JSX.Element;
}

function ManagerTable(props: any) {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);

	const { setSearch, search, showmodal, setShowModal } = props;
	return (
		<>
			{props.managers && (
				<TableComponent
					className='rounded-none rounded-t-0'
					DATA={props.managers}
					COLUMNS={props.columns}
					TITLE=''
					setSize={setSize}
					setPageIndex={(value: number) => page + 1}
					btnTitle='Add Manager'
					btnOnClick={() => setShowModal(true)}
				/>
			)}
		</>
	);
}

export default ManagerTable;
