import { Avater } from "assets";
import axios from "axios";
import { getAdmin, getAdminToken } from "common";
import Button from "components/Button";
import Container from "components/container";
import Input from "components/form-control/input";
import API_URL from "config";
import { selectUser, setUser } from "features/auth/api/slice";
import { allCountries } from "helpers/countries";
import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import LoadingSpinner from "layouts/loadingSpinner";
import UpdatePassword from "layouts/password";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
function AccountSettings() {
  const token = getAdminToken();
  const [passwordshow, setPasswordShow] = useState(false);
  const [file, setFile] = useState<any>();
  const [base64URL, setBase64URL] = useState<any>();
  const [preview, setPreview] = useState<any>(null);
  const [reload, setReload] = useState<number>(1);
  const user = useSelector(selectUser);
  // const user = useSelector(getAdmin);
  const [message, setMessage] = useState<String>("");
  const [userData, setUserData] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [phonevalue, setPhoneValue] = useState<any>("");
  const [err, setErr] = useState("");
  const { register, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber: user.phone,
      firstName: user.firstname,
      email: user.email,
      lastName: user.lastname,
      country: user.country,
      address: user.address,

      organization: userData ? userData[0].organization : user.company,
    },
  });
  const dispatch = useAppDispatch();
  const [copy, setCopy] = useState(false);

  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    axiosCreate
      .get(`${API_URL}/staff/single/${user?._id}`)
      .then(({ data }) => {
        setUserData(data.payload);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user?._id]);

  // create a preview as a side effect, whenever selected file is changed
  // useEffect(() => {
  //   if (!file) {
  //     setPreview(null);
  //     return;
  //   }

  //   const objectUrl = URL.createObjectURL(file);
  //   setPreview(objectUrl);

  //   // free memory when ever this component is unmounted
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [file, reload]);

  // const getBase64 = (file: any) => {
  //   return new Promise((resolve) => {
  //     let fileInfo;
  //     let baseURL: any = "";
  //     // Make new FileReader
  //     let reader = new FileReader() as any;

  //     // Convert the file to base64 text
  //     reader.readAsDataURL(file);

  //     // on reader load somthing...
  //     reader.onload = () => {
  //       // Make a fileInfo Object
  //       console.log("Called", reader);
  //       baseURL = reader.result;
  //       console.log(baseURL);
  //       resolve(baseURL);
  //     };
  //     console.log(fileInfo);
  //   });
  // };

  // const onFileChange = (e: any) => {
  //   if (!e.target.files || e.target.files.length === 0) {
  //     setFile(null);
  //     return;
  //   }

  //   // I've kept this example simple by using the first image instead of multiple
  //   setFile(e.target.files[0]);

  //   getBase64(file)
  //     .then((result) => {
  //       file["base64"] = result;
  //       console.log("File Is", file);
  //       setBase64URL(result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setReload(1 + reload);
  // };

  const formData = [
    {
      label: "First Name",
      type: "text",

      register: { ...register("firstName") },
    },
    {
      label: "Last Name",
      type: "text",

      register: { ...register("lastName") },
    },
    // {
    //   label: "Passowrd",
    //   type: "password",
    //   register: { ...register("password") },
    // },
    {
      label: "Email",

      type: "email",
      register: { ...register("email") },
    },
    {
      label: "Organization",

      type: "text",
      register: { ...register("organization") },
    },
    {
      label: "Phone number",
      number: true,
      type: "number",
      register: { ...register("phoneNumber") },
    },
    {
      label: "Address",

      type: "text",
      register: { ...register("address") },
    },
  ];

  const remove = () => {
    setFile(null);
    setReload(1 + reload);
    // formRef.reset();
  };

  const handlePostSetting = (data: any) => {
    const formData = new FormData();
    setLoading(true);
    setErr("");
    formData.append("firstname", data.firstName);
    formData.append("lastname", data.lastName);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("country", data.country);
    formData.append("phone", phonevalue ? phonevalue : user?.phone);
    formData.append("image", selectedImage != "" ? selectedImage : user.image);

    if (!isValid) {
      setLoading(false);
      setErr("Input a valid phone number");
      return;
    }
    axiosCreate
      .put(`${API_URL}/staff/update/${user?._id}`, formData)
      .then(({ data }) => {
        dispatch(setUser(data?.payload[0]));
        setMessage(data.message);
        setLoading(false);
        // window.location.reload();
        setMessage("Profile updated successfully");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCopy = () => {
    // if (!copy) {

    navigator.clipboard.writeText("239432").then(
      function () {
        setCopy(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );

    setTimeout(() => {
      setCopy(false);
    }, 600);
    // }
  };

  // const downloadQR = () => {
  //   const canvas = document.getElementById("lfzentry") as HTMLCanvasElement;
  //   const pngUrl = canvas?.toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");
  //   let downloadLink = document.createElement("a");
  //   downloadLink.href = pngUrl;
  //   downloadLink.download = "lfzentry.png";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  const downloadQR = () => {
    saveAs(
      "https://api.qrserver.com/v1/create-qr-code/?data=94567&size=400",
      "image.jpg"
    ); // Put your image url here.
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      setSelectedImage(base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePhoneNumberChange = (value: any) => {
    if (value === undefined) {
      setIsValid(false);
    } else {
      setIsValid(isValidPhoneNumber(value));
      setPhoneValue(value);
    }
  };

  return (
    <>
      <Container>
        <>
          <LoadingSpinner loading={loading} />
          {message && (
            <div className="w-full p-4 lg:p-8">
              <div className="p-4  mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                <span className="text-white font-semibold ">{message}</span>
                <div onClick={() => setMessage("")}>
                  <i className="fa-solid fa-circle-xmark text-white"></i>
                </div>
              </div>
            </div>
          )}

          {err && (
            <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
              <span className="text-white font-semibold ">{err}</span>
              <div onClick={() => setErr("")}>
                <i className="fa-solid fa-circle-xmark text-white"></i>
              </div>
            </div>
          )}
          <div className="  p-8">
            <h2 className="font-bold text-2xl mb-4">Profile Details</h2>
            <div className="flex justify-start items-center">
              {!selectedImage ? (
                <img
                  src={user.image ? user.image : Avater}
                  alt=""
                  className="h-32 w-32 rounded-full"
                />
              ) : (
                <img
                  src={selectedImage}
                  alt=""
                  className="h-32 w-32 rounded-full"
                />
              )}
              <div className="pl-6">
                <div className="lg:flex justify-start items-center">
                  <input
                    type="file"
                    className="hidden"
                    id="upload"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="upload"
                    className={`mb-8 lg:mb-0 bg-[#0C198B] text-sm lg:text-base  hover:bg-black text-white transition ease-in-out delay-150 py-3 lg:py-2 px-3 rounded-[10px] hover:-translate-y-1 hover:scale-110   duration-300`}
                  >
                    Upload new photo
                  </label>
                </div>
                <span className="text-xs text-[#B4B4B4] mt-4">
                  Allow JPG, GIF or PNG. Max size of 800K
                </span>
              </div>
            </div>
          </div>
          <hr className="bg-[#ABABAB]" />

          {/* form */}

          <div className="lg:flex justify-end my-3 mx-3 items-center">
            <span
              // htmlFor="upload"
              className={`mb-8 lg:mb-0 cursor-pointer bg-[#0C198B] text-sm lg:text-base  hover:bg-black text-white transition ease-in-out delay-150 py-3 lg:py-2 px-3 rounded-[10px] hover:-translate-y-1 hover:scale-110   duration-300`}
              onClick={() => setPasswordShow(true)}
            >
              Change Password
            </span>
          </div>
          <form
            onSubmit={handleSubmit(handlePostSetting)}
            className="flex justify-between flex-wrap px-8 py-12"
            // ref={formRef}
          >
            {formData.map((item, i) => (
              <div className="w-full lg:w-[45%]">
                {item?.number ? (
                  <PhoneInput
                    name="phoneInput"
                    onChange={handlePhoneNumberChange}
                    defaultCountry={"NG"}
                    className={
                      "input-phone-number border border-gray-400 p-3 rounded-lg bg-gray-100 "
                    }
                    // control={control}
                    // rules={{ required: true }}
                    // value={user.phone}
                    placeholder="your phone"
                  />
                ) : (
                  <Input
                    register={item.register}
                    type={item.type}
                    label={item.label}
                    className="dark:bg-[#2A2A2A] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                )}
              </div>
            ))}

            <div className="w-full lg:w-[45%] mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Country
              </label>

              <select
                id="countries"
                className="border border-gray-300 text-gray-900 text-sm rounded-md outline-none block w-full p-2.5 dark:bg-[#2A2A2A] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                {...register("country")}
              >
                <option selected>Select a country</option>
                {allCountries.map((country, i) => (
                  <option value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
            <div className="flex justify-end space-x-3 items-center w-full">
              <Button
                type={"submit"}
                title="Update"
                bgColor="bg-green-500"
                textColor="text-white"
                className="border hover:bg-black hover:text-white w-64 my-6 mx-auto lg:mx-0"
              />
            </div>
          </form>
        </>
      </Container>
      {user.role !== "admin" && (
        <Container className="shadow-header rounded-lg mt-12 px-8 pt-8 lg:p-8">
          <>
            <div className="">
              <h2 className="font-bold text-2xl mb-4">Delete Account</h2>
            </div>

            <div className="bg-[#FFD89EBD] text-[#FF9900] dark:text-orange-700 p-8 border rounded-xl">
              <p>Are you sure you want to delete your account?</p>
              <p>
                Once you delete your account, there is no going back. Please be
                certain
              </p>
            </div>

            <div className="pt-6">
              <input type={"checkbox"} />
              <span className="text-xs ml-4">
                I confirm my accout deactivation
              </span>
            </div>

            <Button
              // onClick={}
              title="Deactivate Account"
              bgColor="bg-red-500"
              textColor="text-white"
              className="border hover:bg-black hover:text-white w-64 my-6 mx-auto lg:mx-0"
            />
          </>
        </Container>
      )}
      <UpdatePassword show={passwordshow} setShow={setPasswordShow} />
    </>
  );
}

export default AccountSettings;
