import { Navigate, Outlet } from 'react-router-dom';
import { getAdminToken, removeAdminSession } from 'common';
import { useJwt } from 'react-jwt';
const AdminRoute = () => {
	const auth = getAdminToken(); // determine if authorized, from context or however you're doing it
	const { decodedToken, isExpired } = useJwt(auth as any);
	// const token = window.localStorage.getItem("token");

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to welcome page
  if (auth) {
    if (isExpired === true) {
      // AB you can also refresh the token to avoid this stress

      removeAdminSession();
      return <Navigate to="/auth/welcome" />;
    }
    // console.log(isExpired)
  }

  return auth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/auth/welcome" />
  );
};

export default AdminRoute;
