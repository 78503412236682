// import AuthWrapper from 'components/wrappers/auth-wrapper';
// import { useForm } from "react-hook-form";
// import Input from 'components/form-control/input';
import React, { useEffect, useState, useRef } from "react";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { LOGO } from "assets";
import { BALOSH } from "assets";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import Input from "components/form-control/input";
import { getAdminToken, getUser } from "common";
import axios from "axios";
import API_URL from "config";
import LoadingSpinner from "layouts/loadingSpinner";
// import TimeRange from "react-time-range";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { DateRange, StaticTimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro/SingleInputTimeRangeField";
// import { StaticTimePicker } from "@mui/x-date-pickers-pro/StaticTimePicker";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/api/slice";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
interface mainLayoutTypes {
  children: JSX.Element;
}

function VisitorsModal(props: any) {
  // const [value, setValue] = React.useState<any>(() => [
  //   dayjs("2022-04-17T15:30"),
  //   dayjs("2022-04-17T18:30"),
  // ]);
  const [copy, setCopy] = useState(false);
  // const [value, setValue] = React.useState<DateRange<Dayjs> | any>(() =>
  //   dayjs("")
  // );
  const scrollRef = useRef<any>();
  const scrollErr = useRef<any>();
  const [value, setValue] = useState<any>("");
  const [value2, setValue2] = useState<any>("");
  const user = useSelector(selectUser);
  // const [value2, setValue2] = React.useState<DateRange<Dayjs> | any>(() =>
  //   dayjs("")
  // );
  const [temp, setTemp] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("individual");
  const [size, setSize] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [startTime, setStartTime] = useState<any>("");
  const [endTime, setEndTime] = useState<any>("");
  // const { loading, setLoading } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const token = getAdminToken();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [time, setTime] = useState(false);
  const [err, setErr] = useState("");
  const [share, setShare] = useState(false);
  const [nots, setNot] = useState(false);
  const [companyname, setCompanyName] = useState("");
  const [phonevalue, setPhoneValue] = useState<any>("");
  const [isValid, setIsValid] = useState(false);
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });
  useEffect(() => {
    setLoading(true);
    axiosCreate
      .get(`${API_URL}/company`)
      .then(({ data }) => {
        setLoading(false);
        setCompanies(data?.payload[0]);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("an error occured");
        setErr(error?.response?.data?.message);
      });
  }, []);

  const [info, setInfo] = useState<any>(null);
  const { showmodal, setShowModal } = props;
  const { register, handleSubmit } = useForm();
  const formData = [
    {
      label: "Name",
      placeholder: "Enter name",
      type: "text",
      register: { ...register("firstname") },
    },
    {
      label: "Email Address",
      placeholder: "enter email",
      type: "email",
      register: { ...register("email") },
    },
    {
      label: "Designation",
      placeholder: "enter designation",
      type: "text",
      register: { ...register("designation") },
    },
    // {
    //   label: "Address",
    //   placeholder: "enter company address",
    //   type: "text",
    //   register: { ...register("address") },
    // },
    // {
    //   label: "Upload NIN/CEPAC {PDF Format Required}",
    //   //   placeholder: "enter company description",
    //   type: "file",
    //   register: { ...register("nin") },
    // },
  ];
  const individual = [
    {
      label: "Invitee",
      placeholder: "",
      type: "text",
      register: { ...register("name") },
    },
    // {
    //   label: "Email address",
    //   placeholder: "enter email",
    //   type: "email",
    //   register: { ...register("email") },
    // },
  ];

  const truck = [
    {
      label: "Driver's name",
      placeholder: "enter name",
      type: "text",
      register: { ...register("name") },
    },
    // {
    //   label: "Email address",
    //   placeholder: "enter email",
    //   type: "email",
    //   register: { ...register("email") },
    // },
  ];

  const groupbooking = [
    {
      label: "Invitee",
      placeholder: "",
      type: "text",
      register: { ...register("name") },
    },
    // {
    //   label: "Email address",
    //   placeholder: "enter email",
    //   type: "email",
    //   register: { ...register("email") },
    // },
  ];

  const Select = React.forwardRef(
    ({ onChange, onBlur, name, label }: any, ref: any) => (
      <>
        <label className="font-semibold pb-2 text-gray-700">Categories</label>
        <select
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="individual">Individual</option>
          <option value="truck">Truck</option>
          <option value="groupbooking">Group booking</option>
        </select>
      </>
    )
  );

  const Selects = React.forwardRef(
    ({ onChange, onBlur, name, label }: any, ref: any) => (
      <>
        <label className="font-semibold pb-2 text-gray-300 text-sm">
          {label}
        </label>
        {user?.role == "manager" ? (
          <select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={user?.company?._id}>{user?.company?.name}</option>
          </select>
        ) : (
          <select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Select enterprise</option>
            {companies?.map((dats: any) => (
              <option
                onChange={() => setCompanyName(dats?.name)}
                value={`${dats._id}_${dats.name}`}
              >
                {dats.name}
              </option>
            ))}
          </select>
        )}
      </>
    )
  );

  const Company = () => {
    return <Selects label="Enterprise" {...register("company")} />;
  };

  const times = value2.split(":");

  const dates = moment(value).format("L");

  console.log(dates + " " + value2);
  var date1 = new Date(dates + " " + value2);
  console.log(date1);
  const ms = date1.getTime();
  var date = new Date(value);
  var ms2 = date.getTime();

  const TimeComponent = () => {
    return (
      <div className="flex-1">
        <Dialog open={time === true} onClose={() => setTime(false)}>
          <div className="p-10">
            <p className="text-center font-bold text-lg">Schedule Visit:</p>
            <br />
            <label className="text-center">Time in:</label>
            <input
              type="datetime-local"
              id="time"
              name="time"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></input>
            <br />
            <label className="text-center mt-4">Time out:</label>
            <input
              type="time"
              id="time"
              name="time"
              value={value2}
              onChange={(e) => setValue2(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            ></input>
            <br />
          </div>
        </Dialog>
        <Dialog open={schedule === true} onClose={() => setSchedule(false)}>
          {/* <CircularProgress color="secondary" className="m-5" /> */}
          <div className="p-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <DemoItem label="Schedule Date">
                  <DateCalendar
                    value={value2}
                    onChange={(newValue: any) => setValue2(newValue)}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>

            <button
              onClick={() => setSchedule(false)}
              className="p-3 bg-green-700 rounded-lg text-white"
            >
              Done
            </button>
          </div>
        </Dialog>
        <h4 className="text-gray-600 font-bold">Time</h4>
        {value == "" && value2 == "" ? (
          <div className="flex-col space-y-5 items-center">
            <div className="flex mt-3 mx-3">
              <button
                onClick={() => setTime(true)}
                className="p-3 bg-green-600 rounded-lg text-white"
              >
                Now
              </button>
            </div>
            {/* <p className="text-center font-bold text-3xl">OR</p> */}

            {/* <div className="flex justify-center">
              <button
                onClick={() => setSchedule(true)}
                className="p-3 bg-green-600 rounded-lg text-white"
              >
                Schedule visit
              </button>
            </div> */}
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <div
              className="mx-4 border-green-500 border-2 rounded-lg cursor-pointer"
              onClick={() => setTime(true)}
            >
              From: {moment(value).format("MMMM Do YYYY, h:mm a")}
            </div>
            {/* <div
              className="h-10 w-10 rounded-full cursor-pointer bg-green-600 flex items-center justify-center"
              onClick={() => setValue(() => dayjs(""))}
            >
              <i className="fa-solid fa-xmark text-white "></i>
            </div> */}
            <div
              className="mx-4 px-3 border-green-500 border-2 rounded-lg cursor-pointer"
              onClick={() => setTime(true)}
            >
              Till: {value2 ? moment(date1).format("MMMM Do YYYY, h:mm a") : ""}
            </div>
            <div
              className="h-10 w-10 rounded-full cursor-pointer bg-green-600 flex items-center justify-center"
              onClick={() => {
                setValue2("");
                setValue("");
              }}
            >
              <i className="fa-solid fa-xmark text-white "></i>
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleToken = (data: any) => {
    if (!nots) {
      scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setErr("");
      setMessage("");
      setLoading(true);
      window.location.href = "#";
      // if (file.type !== "application/pdf") {
      //   setErr("File type must be PDF");
      //   //   return;
      // }
      const form = new FormData();
      const date = Date.now();
      // // form.append("image", file);
      // form.append("name", data?.firstname);
      // form.append("email", data?.email);
      // form.append("phone", data?.phone);
      // form.append("company", data?.company);
      // form.append("categories", data?.categories);
      // form.append("to", value[1]?.$d);
      // form.append("from", value[0]?.$d);
      // // console.log(value[0]?.$d, "here is the value");
      setCompanyName(data?.company?.split("_")[1]);
      const info = {
        email: data?.email,
        name: data?.name,
        phone: phonevalue,
        company: data?.company?.split("_")[0],
        categories: type,
        to: ms,
        from: value === "" ? date : ms2,
        user: user?.id,
        none: value === "" && value2 === "" ? "true" : "false",
      };

      if (value !== "" && value2 === "") {
        setErr("Please time in and out is required");
        setLoading(false);
        return;
      }
      axiosCreate
        .post(`${API_URL}/code/generate-main`, info)
        .then(({ data }: any) => {
          const decode = data?.payload?.originalcode;
          setInfo(data?.payload);
          console.log(data);
          setCode(decode);
          setQrCode(
            `https://api.qrserver.com/v1/create-qr-code/?data=${decode}&size=${400}x`
          );

          setTimeout(() => {
            setLoading(false);
            setShare(true);
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setErr(error?.response?.data?.message);
        });
    }
  };

  const shareLink = `https://lfz-C.vercel.app?code=${code}&image=${qrCode}`;

  // useEffect(() => {
  //   setQrCode(
  //     `https://api.qrserver.com/v1/create-qr-code/?data=50293&size=${400}x&bgcolor="white"`
  //   );
  // }, []);

  const handleCopy = () => {
    // if (!copy) {
    setNot(true);
    navigator.clipboard.writeText(code).then(
      function () {
        setCopy(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );

    setTimeout(() => {
      setCopy(false);
    }, 600);
    // }
  };
  // console.log(code);

  useEffect(() => {
    scrollRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [err, message]);
  useEffect(() => {
    scrollErr.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [err, message]);

  const handleShare = async () => {
    setErr("");
    scrollErr.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    try {
      setNot(true);
      const response = await fetch(qrCode);
      console.log(response, "here is response");
      // here image is url/location of image
      const blob = await response.blob();
      const file = new File([blob], "share.jpg", { type: blob.type });

      const text = `You have been invited to ${companyname?.toUpperCase()} by ${
        user?.firstname
      }\n\nThis code is valid between ${
        moment(info && info?.createdAt)
          .format("MMMM Do YYYY, h:mm a")
          .split(",")[1]
      } and ${
        moment(info && info?.expiresAt)
          .format("MMMM Do YYYY, h:mm a")
          .split(",")[1]
      } on ${moment(info && info?.expiresAt).format(
        "MMMM Do YYYY"
      )}\n\nPasscode: ${code}`;
      if (navigator.share) {
        await navigator
          .share({
            title: "LFZ invite",
            text,
            files: [file],
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error in sharing", error));
      } else {
        scrollErr.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setErr("system does not support sharing files.");
        // console.log(`system does not support sharing files.`);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handlePhoneNumberChange = (value: any) => {
    if (value === undefined) {
      setIsValid(false);
    } else {
      setIsValid(isValidPhoneNumber(value));
      setPhoneValue(value);
    }
  };

  const handleShares = () => {
    const text = "Bless you lord";
    const imageUrl = "https://";
    const encodedText = encodeURIComponent(`${text}\n${qrCode}`);
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedText}`;

    window.open(whatsappUrl, "_blank");
  };

  // console.log(companyname, "companyname");
  return (
    <>
      {showmodal ? (
        <>
          <div className="scrollbar-hide justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none rounded-lg">
            <div className="relative  w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}

              <form
                className="w-[100%] md:w-[70%] lg:w-[100%]"
                onSubmit={handleSubmit(handleToken)}
              >
                <div className="w-full border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div
                    className="flex bg-[#55657D] items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    // style={{
                    //   background: `linear-gradient(90deg, rgba(12, 57, 174, 0.588542) 46.76%, #AE0C0C 59.32%, rgba(248, 2, 46, 0.0403111) 122.42%, rgba(12, 67, 174, 0) 122.43%)`,
                    // }}
                  >
                    {!share && (
                      <h3 className="text-3xl font-semibold text-white">
                        Invite guest
                      </h3>
                    )}

                    <div
                      className="text-white bg-gradient-to-r cursor-pointer from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                      onClick={() => window.location.reload()}
                    >
                      <i className="fa-solid fa-xmark text-white "></i>
                    </div>
                  </div>
                  {/*body*/}
                  <div
                    ref={scrollErr}
                    className="relative pt-5 lg:w-[100%] lg:px-36 px-4 h-[450px] overflow-y-auto flex-auto"
                  >
                    {loading && (
                      <LoadingSpinner
                        loading={loading}
                        setLoading={setLoading}
                      />
                    )}

                    {message && (
                      <div className="p-4 mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                        <span className="text-white font-semibold ">
                          {message}
                        </span>
                        <div onClick={() => setMessage("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    {err && (
                      <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
                        <span className="text-white font-semibold ">{err}</span>
                        <div onClick={() => setErr("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}

                    {share ? (
                      <div className="flex flex-1 items-center justify-center">
                        <div>
                          <div className="">
                            <div className="flex items-center justify-center">
                              <img src={qrCode} alt="" />
                            </div>
                            <div className="">
                              {/* <h3 className="">Scan</h3> */}
                              <h4 className="flex items-center text-center justify-center font-black text-[50px]">
                                {code}
                              </h4>

                              <div className="pt-2 flex items-center space-x-2 justify-center">
                                <p style={{ fontSize: "20px" }}>
                                  <i
                                    className="fa fa-clipboard text-blue-700"
                                    // style={{ color: "purple" }}
                                    aria-hidden="true"
                                    onClick={() => handleCopy()}
                                  ></i>
                                </p>

                                {copy ? (
                                  <strong>Copied</strong>
                                ) : (
                                  <small className="font-semibold">
                                    Copy code
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <span className="text-red-600 mb-4 font-bold">
                            NOTE: this code is valid until{" "}
                            {moment(info && info?.expiresAt).format(
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </span>
                          <br />
                          <div className="flex mt-4 items-center justify-center">
                            <button
                              className="py-4 rounded bg-blue-700 flex px-6 justify-center items-center text-white"
                              onClick={() => handleShare()}
                            >
                              Forward
                            </button>

                            {/* <div className="" onClick={() => handleShares()}>
                              share{" "}
                            </div> */}
                          </div>
                          {/* <div className="flex justify-center items-center">
                            <FacebookShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <WhatsappShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                            <TwitterShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <LinkedinShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                            <EmailShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                            <TelegramShareButton
                              url={shareLink}
                              color="red"
                              // size={32}
                              // round={true}
                            >
                              <TelegramIcon size={32} round={true} />
                            </TelegramShareButton>
                          </div> */}

                          <footer className="flex h-24 w-full items-center justify-center">
                            <a
                              className="flex items-center justify-center gap-2"
                              href="https://balosh.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Powered by{" "}
                              <img
                                src="https://balosh.com/wp-content/uploads/2022/02/balosh-logo.png"
                                alt="Balosh"
                                width={72}
                                height={16}
                              />
                            </a>
                          </footer>
                        </div>
                      </div>
                    ) : (
                      <div ref={scrollRef}>
                        {/* <Select label="Company" {...register("company")} /> */}
                        <label className="font-semibold pb-2 text-gray-300 text-sm">
                          Categories
                        </label>
                        <select
                          // name={name}
                          // ref={ref}
                          onChange={(e) => setType(e.target.value)}
                          value={type}
                          // onBlur={onBlur}
                          className="mb-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full lg:w-[400px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="individual">Individual</option>
                          <option value="truck">Truck</option>
                          <option value="groupbooking">Group booking</option>
                        </select>
                        <div className="py-3">
                          <PhoneInput
                            name="phoneInput"
                            onChange={handlePhoneNumberChange}
                            defaultCountry={"NG"}
                            className={
                              "input-phone-number border border-gray-400 p-3 rounded-lg bg-gray-100 "
                            }
                            // control={control}
                            // rules={{ required: true }}
                            placeholder="your phone"
                          />
                        </div>
                        {/* <TimeRange
                      startMoment={startTime}
                      endMoment={endTime}
                      // onChange={this.returnFunction}
                    /> */}

                        {type === "individual" && (
                          <>
                            <Company />
                            {individual.map((item, i) => (
                              <>
                                <Input
                                  register={item.register}
                                  type={item.type}
                                  label={item.label}
                                />
                              </>
                            ))}
                            <TimeComponent />
                          </>
                        )}
                        {type === "truck" && (
                          <>
                            <Company />
                            {truck.map((item, i) => (
                              <>
                                <Input
                                  register={item.register}
                                  type={item.type}
                                  label={item.label}
                                />
                              </>
                            ))}
                            <TimeComponent />
                          </>
                        )}
                        {type === "groupbooking" && (
                          <>
                            <Company />
                            {groupbooking.map((item, i) => (
                              <>
                                <Input
                                  register={item.register}
                                  type={item.type}
                                  label={item.label}
                                />
                              </>
                            ))}
                            <TimeComponent />
                          </>
                        )}
                      </div>
                    )}

                    <br />
                  </div>
                  {/*footer*/}
                  {!share && (
                    <div className="flex mb-4 items-center justify-center">
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${
                          !loading ? "bg-emerald-500" : "bg-gray-400"
                        } text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                        type="submit"
                        disabled={loading ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default VisitorsModal;
