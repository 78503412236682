import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import LoadingSpinner from "layouts/loadingSpinner";
import { getAdminToken } from "common";
import axios from "axios";
import API_URL from "config";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/api/slice";
function UpdatePassword({ show, setShow }: any) {
  //   const [open, setOpen] = React.useState(false);
  const [err, setErr] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  console.log(show, "from main");
  const user = useSelector(selectUser);
  const token = getAdminToken();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      // "Content-Type": "multipart/form-data",
    },
  });

  const handleChangePassword = () => {
    setLoading(true);
    setErr("");
    const info = {
      oldpassword: oldpassword,
      newpassword: newpassword,
      confirmpassword: confirmpassword,
    };
    axiosCreate
      .put(`${API_URL}/staff/update-password/${user?._id}`, info)
      .then(({ data }) => {
        setLoading(false);
        setMessage("Password updated successfully");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        // console.log(error);
        setErr(error?.response?.data?.message);
        setLoading(false);
      });
  };
  return (
    <Dialog open={show} onClose={() => ""}>
      <LoadingSpinner loading={loading} />
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent className="lg:w-[500px] w-[350px]">
        {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}

        {message && (
          <div className="p-4 mx-10 mb-10 rounded-lg bg-green-500 flex justify-between items-center">
            <div>
              <span className="text-white font-semibold ">{message}</span>
              <br />
              {message && (
                <span className="text-white font-semibold text-xs">
                  Please wait ....
                </span>
              )}
            </div>
            <div onClick={() => setMessage("")}>
              <i className="fa-solid fa-circle-xmark text-white"></i>
            </div>
          </div>
        )}

        {err && (
          <div className="p-4 mb-10 mx-10 rounded-lg bg-red-500 flex justify-between items-center">
            <span className="text-white font-semibold text-sm">{err}</span>
            <div onClick={() => setErr("")}>
              <i className="fa-solid fa-circle-xmark text-white"></i>
            </div>
          </div>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Old Password"
          type="password"
          fullWidth
          variant="standard"
          value={oldpassword}
          onChange={(e: any) => setOldPassword(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Password"
          type="password"
          fullWidth
          variant="standard"
          value={newpassword}
          onChange={(e: any) => setNewPassword(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Confirm Password"
          type="password"
          fullWidth
          variant="standard"
          value={confirmpassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button onClick={() => handleChangePassword()}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdatePassword;
