// import AuthWrapper from 'components/wrappers/auth-wrapper';
import { useForm } from 'react-hook-form';
// import Input from 'components/form-control/input';
import { LOGO } from 'assets';
import { BALOSH } from 'assets';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { TableComponent } from 'components/table';
import { useState } from 'react';

interface mainLayoutTypes {
	children: JSX.Element;
}

function UserTable(props: any) {
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);

	const { setSearch, search, showmodal, setShowModal } = props;
	return (
		<>
			{props.users && (
				<TableComponent
					className='rounded-none rounded-t-0'
					DATA={props.users}
					COLUMNS={props.columns}
					TITLE=''
					setSize={setSize}
					setPageIndex={(value: number) => page + 1}
					btnOnClick={() => setShowModal(true)}
					btnTitle={'Add new staff'}
				/>
			)}
		</>
	);
}

export default UserTable;
