// import AuthWrapper from 'components/wrappers/auth-wrapper';
// import { useForm } from "react-hook-form";
// import Input from 'components/form-control/input';
import { LOGO } from "assets";
import { BALOSH } from "assets";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "components/form-control/input";
import { useState } from "react";
import { getAdminToken } from "common";
import axios from "axios";
import API_URL from "config";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/api/slice";
interface mainLayoutTypes {
  children: JSX.Element;
}

function CompanyModal(props: any) {
  const token = getAdminToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const { showmodal, setShowModal } = props;
  const { register, handleSubmit } = useForm();
  const user = useSelector(selectUser);
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });
  const formData = [
    {
      label: "Enterprise name",
      placeholder: "enter company name",
      type: "text",
      register: { ...register("companyname") },
    },
    {
      label: "Email address",
      placeholder: "enter company email",
      type: "email",
      register: { ...register("email") },
    },
  ];
  //   const formData = [
  //     {
  //       label: "COMPANY NAME",
  //       placeholder: "Enter company name",
  //       type: "text",
  //       register: { ...register("companyname") },
  //     },
  //     {
  //       label: "Phone Number",
  //       placeholder: "enter company phone",
  //       type: "number",
  //       register: { ...register("phone") },
  //     },
  //     {
  //       label: "Email Address",
  //       placeholder: "enter company email",
  //       type: "email",
  //       register: { ...register("email") },
  //     },
  //     {
  //       label: "Address",
  //       placeholder: "enter company address",
  //       type: "text",
  //       register: { ...register("address") },
  //     },
  //     {
  //       label: "Description",
  //       placeholder: "enter company description",
  //       type: "text",
  //       register: { ...register("description") },
  //     },
  //     {
  //       label: "Upload NIN/CEPAC {PDF Format Required}",
  //       //   placeholder: "enter company description",
  //       type: "file",
  //       register: { ...register("nin") },
  //     },
  //   ];

  const handleCompany = (data: any) => {
    // alert("yeah ");
    setErr("");
    setMessage("");
    setLoading(true);

    const info = {
      name: data.companyname,
      email: data.email,
      admin: user?._id,
    };
    axiosCreate
      .post(`${API_URL}/company/register`, info)
      .then((data) => {
        setLoading(false);
        setMessage("Enterprise has been registered successfully");

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        //   console.log(error);
        setErr(error?.response?.data?.message);
      });
  };
  return (
    <>
      {showmodal ? (
        <>
          <div className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none rounded-lg">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              {loading && (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <div className=" flex justify-center items-center">
                            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
                          </div>
                        </div>
                        {/*footer*/}
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              )}
              <form
                className="w-[100%] md:w-[70%] lg:w-[100%] lg:h-[50vh]"
                onSubmit={handleSubmit(handleCompany)}
              >
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div
                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    style={{
                      background: `linear-gradient(90deg, rgba(12, 57, 174, 0.588542) 46.76%, #AE0C0C 59.32%, rgba(248, 2, 46, 0.0403111) 122.42%, rgba(12, 67, 174, 0) 122.43%)`,
                    }}
                  >
                    <h3 className="text-xl font-semibold text-white">
                      Enterprise registration
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative pt-5 lg:px-36 px-4  overflow-y-auto flex-auto">
                    {message && (
                      <div className="p-4 mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                        <span className="text-white font-semibold ">
                          {message}
                        </span>
                        <div onClick={() => setMessage("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    {err && (
                      <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
                        <span className="text-white font-semibold ">{err}</span>
                        <div onClick={() => setErr("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    {formData.map((item, i) => (
                      <>
                        <Input
                          register={item.register}
                          type={item.type}
                          label={item.label}
                        />
                      </>
                    ))}
                  </div>
                  {/*footer*/}
                  <div className="flex mb-4 items-center justify-center">
                    <button
                      className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                      //   onClick={() => setShowModal(false)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default CompanyModal;
