// import AuthWrapper from 'components/wrappers/auth-wrapper';
// import { useForm } from "react-hook-form";
// import Input from 'components/form-control/input';
import React, { useEffect, useState } from "react";
import { LOGO } from "assets";
import { BALOSH } from "assets";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import Input from "components/form-control/input";
import { getAdminToken } from "common";
import axios from "axios";
import API_URL from "config";
import LoadingSpinner from "layouts/loadingSpinner";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/api/slice";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { animateScroll, Element } from "react-scroll";
interface mainLayoutTypes {
  children: JSX.Element;
}

function UserManagerModal(props: any) {
  // const { loading, setLoading } = props;
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState<boolean>(false);
  const token = getAdminToken();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [phonevalue, setPhoneValue] = useState<any>("");
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });
  useEffect(() => {
    setLoading(true);
    axiosCreate
      .get(`${API_URL}/company`)
      .then(({ data }) => {
        setLoading(false);
        setCompanies(data?.payload[0]);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("an error occured");
        setErr(error?.response?.data?.message);
      });
  }, []);

  const { showmodal, setShowModal, manager } = props;
  const { register, handleSubmit } = useForm();
  const formData = [
    {
      label: "Name",
      placeholder: "",
      type: "text",
      register: { ...register("firstname") },
    },
    {
      label: "Phone",
      placeholder: "",
      type: "number",
      register: { ...register("phone") },
      number: true,
    },
    {
      label: "Email address",
      placeholder: "",
      type: "email",
      register: { ...register("email") },
    },
    {
      label: "Designation",
      placeholder: "",
      type: "text",
      register: { ...register("designation") },
    },

    {
      label: "Upload NIN/CERPAC {PDF Format Required}",
      //   placeholder: "enter company description",
      type: "file",
      register: { ...register("nin") },
    },
    // {
    //   label: "Address",
    //   placeholder: "enter company address",
    //   type: "text",
    //   register: { ...register("address") },
    // },
    // {
    //   label: "Upload NIN/CEPAC {PDF Format Required}",
    //   //   placeholder: "enter company description",
    //   type: "file",
    //   register: { ...register("nin") },
    // },
  ];

  const Select = React.forwardRef(
    ({ onChange, onBlur, name, label }: any, ref: any) => (
      <div className="pb-3" id="topOfModal">
        <p className="font-semibold text-sm pb-1.5 text-gray-300">{label}</p>
        {manager === true ? (
          <select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={user?.company?._id}>{user?.company?.name}</option>
          </select>
        ) : (
          <select
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Select enterprise</option>
            {companies?.map((dats: any) => (
              <option value={dats._id}>{dats.name}</option>
            ))}
          </select>
        )}
      </div>
    )
  );

  const handleStaff = (data: any) => {
    setErr("");
    setMessage("");
    setLoading(true);
    window.location.href = "#";
    const file = data.nin[0];
    console.log(file);
    if (!file) {
      setLoading(false);
      setErr("NIN/CEPAC is required in PDF's format");
    }
    if (file.type !== "application/pdf") {
      setErr("File type must be PDF");
      setLoading(false);
      animateScroll.scrollTo("topOfModal", {
        containerId: "modaliddd", // Replace with the id of your modal container
        duration: 500,
        smooth: "easeInOutQuart",
      });
      return;
    }
    const form = new FormData();
    form.append("image", file);
    form.append("name", data?.firstname);
    form.append("email", data?.email);
    form.append("phone", phonevalue);
    form.append("company", data?.company);
    form.append("designation", data?.designation);
    form.append("user", user?._id);
    // const info = {
    //   name: data?.firstname,
    //   email: data?.email,
    //   phone: phonevalue,
    //   company: data?.company,
    //   designation: data?.designation,
    //   user: user?._id,
    // };
    if (!isValid) {
      setLoading(false);
      setErr("Input a valid phone number");
      return;
    }

    axiosCreate
      .post(`${API_URL}/staff/register`, form)
      .then((data) => {
        setMessage("Staff has been registered successfully");
        setLoading(false);
        setTimeout(() => {
          setLoading(true);
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        animateScroll.scrollTo("topOfModal", {
          containerId: "modaliddd", // Replace with the id of your modal container
          duration: 500,
          smooth: "easeInOutQuart",
        });
        setErr(error?.response?.data?.message);
      });
  };

  const handlePhoneNumberChange = (value: any) => {
    if (value === undefined) {
      setIsValid(false);
    } else {
      setIsValid(isValidPhoneNumber(value));
      setPhoneValue(value);
    }
  };
  return (
    <>
      {showmodal ? (
        <>
          <div
            id="modaliddd"
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none rounded-lg"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}

              <form
                className="w-[100%] md:w-[70%] lg:w-[100%]"
                onSubmit={handleSubmit(handleStaff)}
              >
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div
                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    style={{
                      background: `linear-gradient(90deg, rgba(12, 57, 174, 0.588542) 46.76%, #AE0C0C 59.32%, rgba(248, 2, 46, 0.0403111) 122.42%, rgba(12, 67, 174, 0) 122.43%)`,
                    }}
                  >
                    <h3 className="text-3xl font-semibold text-white">
                      Staff registration
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="relative pt-5 lg:w-[600px] lg:px-36 px-4 h-[450px] overflow-y-auto flex-auto">
                    {loading && (
                      <LoadingSpinner
                        loading={loading}
                        setLoading={setLoading}
                      />
                    )}

                    {message && (
                      <div className="p-4 mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                        <span className="text-white font-semibold ">
                          {message}
                        </span>
                        <div onClick={() => setMessage("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    {err && (
                      <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
                        <span className="text-white font-semibold ">{err}</span>
                        <div onClick={() => setErr("")}>
                          <i className="fa-solid fa-circle-xmark text-white"></i>
                        </div>
                      </div>
                    )}
                    <Select label="Enterprise" {...register("company")} />
                    {formData.map((item, i) => (
                      <>
                        {item?.number ? (
                          <div className="pb-4">
                            <p className="font-semibold pb-1.5  text-sm text-gray-300">
                              Phone number
                            </p>
                            <PhoneInput
                              name="phoneInput"
                              onChange={handlePhoneNumberChange}
                              defaultCountry={"NG"}
                              className={
                                "input-phone-number border border-gray-400 p-2 rounded-lg bg-gray-100 "
                              }
                              // control={control}
                              // rules={{ required: true }}
                              placeholder="your phone"
                            />
                          </div>
                        ) : (
                          <div className="py-2">
                            <Input
                              register={item.register}
                              type={item.type}
                              label={item.label}
                            />
                          </div>
                        )}
                      </>
                    ))}

                    <br />
                  </div>
                  {/*footer*/}
                  <div className="flex mb-4 items-center justify-center">
                    <button
                      className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default UserManagerModal;
