// import AuthWrapper from 'components/wrappers/auth-wrapper';
import { useForm } from 'react-hook-form';
// import Input from 'components/form-control/input';
import { LOGO } from 'assets';
import { BALOSH } from 'assets';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { TableComponent } from 'components/table';
import { useState } from 'react';

interface mainLayoutTypes {
	children: JSX.Element;
}

function CompanyRecordTable(props: any) {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);

	const { setSearch, search, showmodal, setShowModal } = props;
	return (
		<>
			<div className='mt-4 lg:flex  lg:mx-0 mx-4 justify-between items-center mb-5'></div>
			{props.visitors && (
				<TableComponent
					className='rounded-none rounded-t-0'
					DATA={props.visitors}
					COLUMNS={props.columns}
					TITLE=''
					setSize={setSize}
					setPageIndex={(value: number) => page + 1}
					btnOnClick={undefined}
					btnTitle={undefined}
				/>
			)}
		</>
	);
}

export default CompanyRecordTable;
