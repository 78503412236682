import MainLayout from "layouts";
import { Route, Routes, useNavigate } from "react-router-dom";
import AccountSettings from "./admin/account-settings";
import Communication from "./admin/communication";
import CommunicationManager from "./manager/communication";
import Company from "./admin/Company";
import Dashboard from "./admin/Dashboard";
import Emergency from "./admin/emergency";
import Information from "./admin/information";
import RegisteredManagers from "./admin/registered-managers";
import VisitorsManagement from "./admin/visitors-management";
import { useDispatch, useSelector } from "react-redux";
// import { userData } from "../src/store/reducer/authReducer";
import { selectToken, selectUser, setUser } from "../auth/api/slice";
import FeedbackComplaint from "./admin/feedback";
import { useEffect, useState } from "react";
import IP_URL from "config/ipurl";
import { io } from "socket.io-client";
import ChatScreen from "./admin/chat-screen";
import ChatScreenManager from "./manager/chat-screen";
import FeedbackMessaging from "./admin/feedback-messaging";
import ManagerDetails from "./admin/Managerdetails";
import ManagerDashboard from "./manager/Dashboard";
import VisitorsManagementManager from "./manager/visitors-management";
import RegisteredUsers from "./admin/registered-users";
import RegisteredUsersManager from "./manager/registered-users";
import EmergencyManager from "./manager/emergencymanager";
import InformationMgr from "./manager/information";
import AccountSettingsManager from "./manager/account-settings";
import ChatMgr from "./manager/chats";
import CompanyRecords from "./admin/company-records";
import TestCode from "./admin/test-code";
import { getAdmin, getManager } from "common";
import API_URL from "config";
import axios from "axios";
import AdminRoute from "routes/AdminRoute";
import ManagerRoute from "routes/ManagerRoute";
import AdminInformation from "./admin/adminInformation";
import SingleInformationAdmin from "./admin/information-details";
function DashboardRoutes() {
  const manager = getManager();
  const admin = getAdmin();
  const user = admin ? admin : manager;
  const dispatch = useDispatch();
  // const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  //   console.log(user, "main user details");
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [socket, setSocket] = useState(null as any);
  const [userdetails, setUserdata] = useState(null);
  const [notification, setNotification] = useState(null as any);
  useEffect(() => {
    setSocket(
      io(IP_URL, {
        transports: ["websocket", "polling", "flashsocket"],
      })
    );
  }, []);

  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });

  const getUserNotification = async (reload: any) => {
    // console.log(reload, "here is reload");
    if (reload == false) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    // setLoading(true);
    try {
      const notificationdats = await axiosCreate.get(
        `${API_URL}/staff/notifications/${user?.id}`
      );
      console.log(notificationdats?.data?.payload);
      setNotification(notificationdats?.data?.payload);
      setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  const getUserDetails = async (reload: any) => {
    try {
      if (reload == false) {
        setLoading(false);
      } else {
        setLoading(true);
      }
      const userdetails = await axiosCreate.get(
        `${API_URL}/staff/single/${user?.id}`
      );
      dispatch(setUser(userdetails?.data?.payload[0]));
      setUserdata(userdetails?.data?.payload[0]);
      setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      socket?.emit("userId", user?.id);
      socket?.on("update", (type: any) => {
        // setUserdata(msg);
        // console.log(update);
        if (type == "notification_update") {
          getUserNotification(false);
        }
        if (type == "user_update") {
          getUserDetails(false);
        }
      });
      // socket?.on("update-here", (dats) => {});
    }
    // getUserDetails();
  }, [socket]);

  useEffect(() => {
    getUserNotification(true);
    getUserDetails(true);
  }, []);

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime") || String;
    if (expireTime < Date.now().toFixed()) {
      localStorage.clear();
      navigate("/auth/login");
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 1800000;

    localStorage.setItem("expireTime", expireTime.toFixed());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("mousemove", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);
  return (
    <MainLayout
      loading={loading}
      setLoading={setLoading}
      notification={notification}
      user={userdetails}
      socket={socket}
    >
      <Routes>
        {/* ADMIN ROUTE */}

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin" element={<Dashboard />} />
        </Route>

        <Route path="admin/login" element={<Dashboard />} />

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/company" element={<Company />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route
            path="admin/visitor-management"
            element={<VisitorsManagement />}
          />
        </Route>
        <Route path="/" element={<AdminRoute />}>
          <Route
            path="admin/registered-managers"
            element={<RegisteredManagers />}
          />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/registered-users" element={<RegisteredUsers />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/information" element={<Information />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/all-information" element={<AdminInformation />} />
        </Route>
        <Route path="/" element={<AdminRoute />}>
          <Route
            path="admin/all-information/information-details"
            element={<SingleInformationAdmin />}
          />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/communication" element={<Communication />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/communication/chat" element={<ChatScreen />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/code" element={<TestCode />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/emergency" element={<Emergency />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/account-settings" element={<AccountSettings />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="/admin/manager-details" element={<ManagerDetails />} />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route
            path="admin/feadback-complaints/messaging"
            element={<FeedbackMessaging />}
          />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route
            path="admin/feadback-complaints"
            element={<FeedbackComplaint />}
          />
        </Route>

        <Route path="/" element={<AdminRoute />}>
          <Route path="admin/company-records" element={<CompanyRecords />} />
        </Route>

        {/* MANAGER ROUTE */}
        {/* manager routes */}

        <Route path="/" element={<ManagerRoute />}>
          <Route path="manager" element={<ManagerDashboard />} />
        </Route>
        <Route path="/" element={<ManagerRoute />}>
          <Route
            path="manager/visitor-management"
            element={<VisitorsManagementManager />}
          />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route path="manager/staff" element={<RegisteredUsersManager />} />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route path="manager/emergency" element={<EmergencyManager />} />
        </Route>
        <Route path="/" element={<ManagerRoute />}>
          <Route path="manager/information" element={<InformationMgr />} />
        </Route>
        <Route path="/" element={<ManagerRoute />}>
          <Route path="manager/information/chat" element={<ChatMgr />} />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route
            path="manager/account-settings"
            element={<AccountSettingsManager />}
          />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route
            path="manager/communication"
            element={<CommunicationManager />}
          />
        </Route>

        <Route path="/" element={<ManagerRoute />}>
          <Route
            path="manager/communication/chat"
            element={<ChatScreenManager />}
          />
        </Route>
      </Routes>
    </MainLayout>
  );
}

export default DashboardRoutes;
