import {
  VISITOR_BLUE,
  VISITOR_GREEN,
  VISITOR_PURPLE,
  VISITOR_RED,
} from "assets";
import { Zoom } from "react-reveal";
import React, { useState, useEffect } from "react";
import { getAdminToken } from "common";
import axios from "axios";
import API_URL from "config";
import { useSelector } from "react-redux";
import { selectUser } from "features/auth/api/slice";
function VisitorsWidget({ data }: any) {
  const user = useSelector(selectUser);
  const token = getAdminToken();
  const [communication, setCommunication] = useState<any>(0);
  const [active, setActive] = useState<any>(0);
  const [expired, setExpired] = useState<any>(0);
  const [monthly, setMonthly] = useState<any>(0);
  const [information, setInformation] = useState<any>(0);
  //   const [info, setMonthly] = useState<any>(0);
  const [yearly, setYearly] = useState<any>(0);
  const [total, setTotal] = useState<any>(0);
  const [details, setDetails] = useState<any>();
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });

  useEffect(() => {
    if (user.role == "admin") {
      axiosCreate
        .get(`${API_URL}/code/all`)
        .then(({ data }: any) => {
          const comm = data?.payload[0];

          setDetails(comm);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axiosCreate
        .get(`${API_URL}/code/all?company=${user?.company?.id}`)
        .then(({ data }: any) => {
          const comm = data?.payload[0];

          setDetails(comm);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <h3 className="font-bold text-white text-center">
        Today visitors analytics
      </h3>
      <div className="flex  justify-around items-center flex-wrap  mt-4 lg:mt-0 w-full">
        <Zoom>
          <div
            className={`bg-white  w-[48%] md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_PURPLE} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8 ">
              <span className={` text-black text-xs pt-2 text-center `}>
                Today visits
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.todayCount}
              </p>
            </div>
          </div>

          <div
            className={`bg-white  w-[48%] md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_GREEN} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8 ">
              <span className={` text-black text-xs pt-2 text-center `}>
                Today Active visits
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.activeCount}
              </p>
            </div>
          </div>
          <div
            className={`bg-white  w-[48%]  md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_BLUE} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8">
              <span className={` text-black text-xs pt-2 text-center `}>
                Total Visits(lifetime)
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.totalCount}
              </p>
            </div>
          </div>

          <div
            className={`bg-white  w-[48%] md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_PURPLE} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8 ">
              <span className={` text-black text-xs pt-2 text-center `}>
                Cancelled visits
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.cancelledCount}
              </p>
            </div>
          </div>
          <div
            className={`bg-white  w-[48%] md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_PURPLE} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8 ">
              <span className={` text-black text-xs pt-2 text-center `}>
                Inactive codes
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.inactiveCount}
              </p>
            </div>
          </div>

          <div
            className={`bg-white w-[48%]   md:w-[30%] shadow-header rounded-lg mb-4 mt-4 p-4 lg:mb-0 flex justify-start items-center`}
          >
            <img src={VISITOR_RED} alt="" className="w-full lg:w-12" />

            <div className="flex justify-center items-center flex-col ml-8">
              <span className={` text-black text-xs pt-2 text-center `}>
                Expired visits
              </span>
              <p
                className={`text-black font-bold text-center pt-2 text-xl lg:text-3xl`}
              >
                {details?.expiredCount}
              </p>
            </div>
          </div>
        </Zoom>
      </div>
    </>
  );
}

export default VisitorsWidget;
