import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useState, useEffect } from "react";
import { getAdminToken } from "common";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RedeemCode(props: any) {
  const { url, title, id, writeup } = props;
  const token = getAdminToken();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const axiosCreate = axios.create({
    headers: {
      authorization: "Bearer " + token,
      //   "Content-Type": "multipart/form-data",
    },
  });

  const { open, setOpen, status } = props;
  useEffect(() => {
    // alert(id)
  }, []);
  // console.log(id)
  const handleUpdate = (e: any) => {
    // setShow(false);
    // const deleteurl = API_URL + "/admin/delete-order/" + id;
    // console.log(id);
    e.preventDefault();
    setLoading(true);
    setErr("");
    setMessage("");
    axiosCreate
      .put(`${url}`, { status: status, id: id })
      .then(({ data }: any) => {
        setLoading(false);
        setMessage(`Code has been redeem successfully and valid for 5mins`);

        setTimeout(() => {
          window.location.reload();
        }, 2500);
      })
      .catch((err) => {
        setErr(err?.response?.data?.data);
        setLoading(false);
      });

    // if (logout === true) {
    //   setTimeout(() => {
    //     removeUserSession(), navigate("/auth");
    //   }, 4000);
    //   // setTimeout(() => setMessage("Logout successfull"), 2000);
    // }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Redeem code?"}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              {message && (
                <div className="p-4 mb-10 rounded-lg bg-green-500 flex space-x-2 justify-between items-center">
                  <span className="text-white font-semibold ">{message}</span>
                  <div onClick={() => setMessage("")}>
                    <i className="fa-solid fa-circle-xmark text-white"></i>
                  </div>
                </div>
              )}
              {err && (
                <div className="p-4 mb-10 rounded-lg bg-red-500 flex justify-between items-center">
                  <span className="text-white font-semibold ">{err}</span>
                  <div onClick={() => setErr("")}>
                    <i className="fa-solid fa-circle-xmark text-white"></i>
                  </div>
                </div>
              )}
            </>
          )}
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to redemn this code for exit purpose?.
            Redeeming this code wiil make this code active and valid for extra 5mins
          </DialogContentText>
        </DialogContent>
        {!loading || message ? (
          <DialogActions>
            <Button onClick={handleClose}>Go back</Button>
            <button
              className="p-3 rounded-lg bg-green-500 text-white"
              onClick={(e) => handleUpdate(e)}
            >
              Redeem
            </button>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
}

export default RedeemCode;
